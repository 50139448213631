@import 'animations';

html {
    scroll-behavior: smooth; 

    ::-webkit-scrollbar{
        width: 0px;
        height: 0px;
    }

    ::-webkit-scrollbar-thumb{
        background: #07114a;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover{
        background: #02082c;
    }
    
    ::-webkit-scrollbar-track{
        background: #ff000000;
    }

    body{
        margin: 0;
        overflow-x: hidden;

        .scrollable{
            ::-webkit-scrollbar{
                width: 3px;
                height: 3px;
            }
            *{
                ::-webkit-scrollbar{
                    width: 3px;
                    height: 3px;
                }
            }
        }

        *, *::after, *::before{
            transition: all ease-out 200ms;
            box-sizing: border-box;
        }

        .App{
            min-height: 100vh;
        }
        .steps-container{
            transition: unset;    
            overflow: hidden;
            height: 75%;
            // min-height: 350px;
            *, *::after, *::before{
                transition: unset;
            }
        }

        .MuiDialog-paper{
            max-width: 94vw !important;
            position: unset;
        }

        .recharts-surface{
            overflow: visible;
        }

        a{
            text-decoration: none;
            color: inherit;
        }

        img{
            max-width: 100%;
            max-height: 100%;
        }

        //position
        .relative{
            position: relative;
        }
        
        .absolute{
            position: absolute;
        }

        .fixed{
            position: fixed;
        }

        //flex
        .flex{
            display: flex;
        }

        .column{
            flex-direction: column;
        }

        .align-center{
            align-items: center;
        }

        .align-start{
            align-items: flex-start;
        }

        .align-end{
            align-items: flex-end;
        }

        .align-base{
            align-items: baseline;
        }

        .justify-center{
            justify-content: center;
        }

        .justify-start{
            justify-content: flex-start;
        }

        .justify-end{
            justify-content: flex-end;
        }

        .justify-between{
            justify-content: space-between;
        }

        .wrap{
            flex-wrap: wrap;
        }

        .nowrap{
            flex-wrap: nowrap;
        }

        .row{
            flex-direction: row;
        }

        .row-reverse{
            flex-direction: row-reverse;
        }

        .theguy_logo{
            position: absolute;
            left: 50%;
            bottom: 2%;
            transform: translateX(-50%);

            svg{
                path{
                    fill: #fff;
                }
            }

            &:hover{
                svg{
                    path{
                        fill: #33b80e;
                    }
                }
            }
        }
    }
}